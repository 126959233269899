@font-face {
  font-family: "HelveticaNeue";
  src: url('./fonts/HelveticaNeue/HelveticaNeueLTCom-Roman.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url('./fonts/HelveticaNeue/HelveticaNeueLTCom-Bd.ttf') format("truetype");
  font-weight: bold;
}

html {

  
}

body {
  overflow: visible;
  background: url('./assets/bg-image.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  min-width: 100%;
  min-height: 100%;
  height: auto;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print {
  body {
    background: none !important;
    max-width: 100%;
    max-height: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'HelveticaNeue', 'Helvetica Neue', 'Courier New',
    monospace !important;
}

.ecovio-calculator-App-index {
  overflow: visible;
  font-family: -apple-system, BlinkMacSystemFont, 'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  sans-serif !important;
  color:rgb(0, 0, 0);

 
  top: 0;
  left: 0;
  /*
  min-width: 100%;
  min-height: 100%;
  */
  float: none;
  padding: none;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  #background-video{
    min-width: 100%;
    min-height: 100%;
    float: none;
    top: 0;
    padding: none;
    position: fixed;
  
    }