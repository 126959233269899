@font-face {
  font-family: "HelveticaNeue";
  src: url('./fonts/HelveticaNeue/HelveticaNeueLTCom-Roman.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url('./fonts/HelveticaNeue/HelveticaNeueLTCom-Bd.ttf') format("truetype");
  font-weight: bold;
}

/* MOBILE PART START */
@media only screen and (max-width: 600px) {
  body {
    color: red;
    overflow: auto !important;
  }

  .App {
    color: red;
    overflow: auto !important;
  }
}

/* MOBILE PART END */


body {
  overflow: auto !important;
}

.App {
  overflow: auto !important;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.mui-active-icon {
  color: #65ac1e !important;
}

.App-link {
  color: #65ac1e;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ecovio-calculator-App-index {
/*background-image: url('./assets/bg-image.jpg');*/
overflow: visible;
}

.print-only {
  display: none;
  background-image: url('./assets/print-BASFo_wh100lg_4c.png');
}
.stepperbg { 
  background-color: bisque;
  background-image: linear-gradient(white,white);
}

@media print {
  .noPrint {
    display: none;
  } 
  .print-only {
    display:block;
    width: 25%;
  }

}